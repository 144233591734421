//non admin
//I've decided to do this page NOT by venue, but just a long list
import React, { useState, useEffect } from 'react'
import './FullSchedule.css'
import { Link } from 'react-router-dom'
import Button from '../components/UIElements/Button'
import ErrorModal from '../components/UIElements/ErrorModal'
import Modal from '../components/UIElements/Modal'
import { useNavigate } from 'react-router-dom'
import Footer from '../components/UIElements/Footer'
import LoadingSpinner from '../components/UIElements/LoadingSpinner'
import COCHomeNavigation from '../components/Navigation/COCHomeNavigation'
//import sloths from '../../shared/components/images/Sloths_Name.png'

const CodeOfConduct = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [showSuccessfulPaymentModal, setShowSuccessfulPaymentModal] =
		useState(false)
	const [firstName, setFirstName] = useState()
	const [lastName, setLastName] = useState()
	const [team, setTeam] = useState()
	const [season, setSeason] = useState()
	const [date, setDate] = useState()
	//
	//
	//
	const getFirstName = (event) => {
		setFirstName(event.target.value)
	}
	const getLastName = (event) => {
		setLastName(event.target.value)
	}
	const getTeam = (event) => {
		setTeam(event.target.value)
	}
	const getSeason = (event) => {
		setSeason(event.target.value)
	}
	const getDate = (event) => {
		setDate(event.target.value)
	}
	//
	//
	//
	let navigate = useNavigate()
	//
	//
	//
	const registerSubmitHandler = async (event) => {
		event.preventDefault()
		try {
			setIsLoading(true)
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/registration/codeOfConduct',
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						firstName,
						lastName,
						team,
						season,
						date,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error('ERROR: ' + responseData.message)
			}

			console.log('you are here, lets show the modal')
			showSuccessfulPaymentHandler()
			//setIsLoading(false)
		} catch (err) {
			console.log('error: ' + err)
			setIsLoading(false)
			setError(
				err.message + ': Something went wrong with creating a code of conduct.'
			)
		}
	}
	//
	//
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//
	//When user clicks OKAY on the popup modal (popup that says their confirmation was successful)
	//all this does is A: close the popup, and B: go back to the home page
	const okHandler = () => {
		console.log('ok has been clicked')
		setShowSuccessfulPaymentModal(null)
		navigate('/')
	}
	//
	//
	//
	const showSuccessfulPaymentHandler = () => {
		console.log('showSuccessfulPaymentModal? ' + showSuccessfulPaymentModal)
		setShowSuccessfulPaymentModal(true)
	}
	//
	//
	//
	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<Modal
					show={showSuccessfulPaymentModal}
					header='Thank You!'
					footerClass='place-item__modal-actions'
					footer={
						<React.Fragment>
							<p className='modalText'>
								Thank you for signing the Code of Conduct. If you haven't
								already, be sure to register your team for the upcoming season.
							</p>
							<br></br>
							<button
								className='okButton buttonButton'
								inverse
								onClick={okHandler}
							>
								OKAY
							</button>
						</React.Fragment>
					}
				></Modal>
				<ErrorModal error={error} onClear={errorHandler} />
				<div>
					{isLoading && <LoadingSpinner asOverlay />}
					<main>
						<h2>Coaches Code of Conduct</h2>
						<div className='rcard'>
							<div className='rcard-body rcard-padding'>
								<form
									className='register-form'
									onSubmit={registerSubmitHandler}
								>
									<div style={{ marginLeft: '1.5rem', marginBottom: '3rem' }}>
										<ul>
											<li>
												Winning is a consideration, but not the only one, nor
												the most important one. Care more about the child than
												winning the game. Remember, players are involved in
												hockey for fun and enjoyment.
											</li>
											<li>
												Be a positive role model to your players. Display
												emotional maturity and be alert to the physical safety
												of players As a coach, you are an extension of your
												organization and players. You are expected to be a good
												citizen at all events in which PBHL players, parents,
												coaches might attend.
											</li>
											<li>
												Be generous with your praise when it is deserved; be
												consistent and honest; be fair and just; do not
												criticize players publicly; learn to be a more effective
												communicator and coach; don’t yell at players.
											</li>
											<li>
												Adjust to personal needs and problems of players; be a
												good listener; never verbally or physically abuse a
												player, official, or parent; give all players the
												opportunity to improve their skills, gain confidence and
												develop self-esteem; teach players the basics.
											</li>
											<li>
												Organize practices that are fun and challenging for your
												players. Familiarize yourself with the rules, techniques
												and strategies of hockey; encourage all your players to
												be team players.
											</li>
											<li>
												Maintain an open line of communication with your
												players’ parents. Explain the goals and objectives of
												your association. Set expectations up front on what they
												should expect from you as a coach and what you expect
												from them as parents.
											</li>
											<li>
												Be concerned with the overall development of your
												players.
											</li>
											<li>
												Removal from a game, for any reason, will result in a
												one game suspension for that coach.
											</li>
											<li>
												Purposefully not putting your team in the best situation
												to win, for seeding results, will conclude in a meeting
												with the Pennsylvania Ball Hockey League board and
												disciplinary measures will potentially be handed down.
												While winning is not the most important thing,
												“sandbagging” will not be tolerated and does not set the
												example we are striving for as a sport, to our athletes
												or parents.
											</li>
											<li>
												Social Media shall not be used to defame or talk
												negatively of any coach, player, team or organization
												within the PBHL. Any failure to abide by this will
												result in suspension or removal of coaching duties.
											</li>
											<li>
												Familiarize yourself with the guidelines regarding
												suspensions, equipment requirements, and rules
												surrounding the PBHL. These can be found on the PBHL
												website.
											</li>
											<li>
												Do not approach a referee regarding a suspension until
												after the game has occurred. Doing so in a disrespectful
												manner could result in possible suspension for the coach
												as well.
											</li>
										</ul>
									</div>
									<div
										className='underline-blue2'
										style={{ marginBottom: '3rem' }}
									></div>
									<div className='row'>
										<div className='row'>
											<div className='col'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtFirstName'
														style={{ fontWeight: 'bold' }}
													>
														First Name: <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtFirstName'
														type='text'
														id='txtFirstName'
														tabIndex='6'
														className='rform-control'
														placeholder='First Name'
														onChange={getFirstName}
														required
													/>
												</div>
											</div>
											<div className='col'>
												<div
													className='form-group fg-line net'
													style={{ marginBottom: '2rem' }}
												>
													<label
														htmlFor='txtLastName'
														style={{ fontWeight: 'bold' }}
													>
														Last Name: <span className='c-red'>*</span>
													</label>
													<input
														name='ctl00$cphBody$txtLastName'
														type='text'
														id='txtLastName'
														tabIndex='7'
														className='rform-control'
														onChange={getLastName}
														placeholder='Last Name'
														required
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-8 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtTeam' style={{ fontWeight: 'bold' }}>
													Organization and Team:{' '}
													<span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtTeam'
													type='text'
													id='txtTeam'
													tabIndex='8'
													className='rform-control'
													placeholder='Team'
													onChange={getTeam}
													required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-sm-12 col-lg-6'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='season' style={{ fontWeight: 'bold' }}>
													Season: <span className='c-red'>*</span>
												</label>
												<input
													id='season'
													type='text'
													tabIndex='9'
													placeholder='Season'
													className='rform-control'
													onChange={getSeason}
													required
												/>
											</div>
										</div>
									</div>
									<div className='row'>
										<div className='col-lg-6 col-lg-3'>
											<div
												className='form-group fg-line net'
												style={{ marginBottom: '2rem' }}
											>
												<label htmlFor='txtDate' style={{ fontWeight: 'bold' }}>
													Todays Date: <span className='c-red'>*</span>
												</label>
												<input
													name='ctl00$cphBody$txtCity'
													type='date'
													id='txtDate'
													tabIndex='10'
													className='rform-control'
													placeholder='Date'
													onChange={getDate}
													required
												/>
											</div>
										</div>
									</div>
									<Button type='submit'>CONFIRM</Button>
								</form>
							</div>
						</div>
					</main>
				</div>
				<Footer />
			</React.Fragment>
		</div>
	)
}

export default CodeOfConduct
