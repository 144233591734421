//This is the Admin Registration Setup Page.  It is used by admins to
//set up the type of league, event, or activity that users will register for.
//Whatever is added to this page will show up as an option for the users
//on the 'Register' page.
import React, { useEffect, useState } from 'react'
import './AdminRegistration.css'
import { NavLink, Link, useNavigate } from 'react-router-dom'
import Button from '../../shared/components/UIElements/Button'
import emailjs from 'emailjs-com'
import AdminButton from '../../shared/components/UIElements/AdminButton'
import AdminNavigation from './AdminNavigation'
import ErrorModal from '../../shared/components/UIElements/ErrorModal'
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner'
import ModalEmail from '../../shared/components/UIElements/ModalEmail'
import Modal from '../../shared/components/UIElements/Modal'
import { useHttpClient } from '../../shared/hooks/http-hook'

const AdminRegisteredTeams = () => {
	const [isLoading, setIsLoading] = useState(false)
	const [error, setError] = useState()
	const [loadedTeams, setLoadedTeams] = useState()
	const [year, setYear] = useState()
	const [emailModal, setEmailModal] = useState()
	const [rosterModal, setRosterModal] = useState()
	const [filteredLoadedTeams, setFilteredLoadedTeams] = useState()
	const [emailAddresses, setEmailAddresses] = useState()
	//const [filteredByYear, setFilteredByYear] = useState()
	const [teamName, setTeamName] = useState()
	const [dateOfLastEdit, setDateOfLastEdit] = useState()
	const [teamId, setTeamId] = useState()
	const [showConfirmMoveToArchiveModal, setShowConfirmMoveToArchiveModal] =
		useState(false)
	const [teamToArchive, setTeamToArchive] = useState()
	const [teamIdForCommittedEdits, setTeamIdForCommittedEdits] = useState()
	const { sendRequest, clearError } = useHttpClient()
	//
	const [name1, setName1] = useState()
	const [number1, setNumber1] = useState()
	const [BY1, setBY1] = useState()
	const [isGoalie1, setIsGoalie1] = useState()
	const [isTaxi1, setIsTaxi1] = useState()
	//
	const [name2, setName2] = useState()
	const [number2, setNumber2] = useState()
	const [BY2, setBY2] = useState()
	const [isGoalie2, setIsGoalie2] = useState()
	const [isTaxi2, setIsTaxi2] = useState()
	//
	const [name3, setName3] = useState()
	const [number3, setNumber3] = useState()
	const [BY3, setBY3] = useState()
	const [isGoalie3, setIsGoalie3] = useState()
	const [isTaxi3, setIsTaxi3] = useState()
	//
	const [name4, setName4] = useState()
	const [number4, setNumber4] = useState()
	const [BY4, setBY4] = useState()
	const [isGoalie4, setIsGoalie4] = useState()
	const [isTaxi4, setIsTaxi4] = useState()
	//
	const [name5, setName5] = useState()
	const [number5, setNumber5] = useState()
	const [BY5, setBY5] = useState()
	const [isGoalie5, setIsGoalie5] = useState()
	const [isTaxi5, setIsTaxi5] = useState()
	//
	const [name6, setName6] = useState()
	const [number6, setNumber6] = useState()
	const [BY6, setBY6] = useState()
	const [isGoalie6, setIsGoalie6] = useState()
	const [isTaxi6, setIsTaxi6] = useState()
	//
	const [name7, setName7] = useState()
	const [number7, setNumber7] = useState()
	const [BY7, setBY7] = useState()
	const [isGoalie7, setIsGoalie7] = useState()
	const [isTaxi7, setIsTaxi7] = useState()
	//
	const [name8, setName8] = useState()
	const [number8, setNumber8] = useState()
	const [BY8, setBY8] = useState()
	const [isGoalie8, setIsGoalie8] = useState()
	const [isTaxi8, setIsTaxi8] = useState()
	//
	const [name9, setName9] = useState()
	const [number9, setNumber9] = useState()
	const [BY9, setBY9] = useState()
	const [isGoalie9, setIsGoalie9] = useState()
	const [isTaxi9, setIsTaxi9] = useState()
	//
	const [name10, setName10] = useState()
	const [number10, setNumber10] = useState()
	const [BY10, setBY10] = useState()
	const [isGoalie10, setIsGoalie10] = useState()
	const [isTaxi10, setIsTaxi10] = useState()
	//
	const [name11, setName11] = useState()
	const [number11, setNumber11] = useState()
	const [BY11, setBY11] = useState()
	const [isGoalie11, setIsGoalie11] = useState()
	const [isTaxi11, setIsTaxi11] = useState()
	//
	const [name12, setName12] = useState()
	const [number12, setNumber12] = useState()
	const [BY12, setBY12] = useState()
	const [isGoalie12, setIsGoalie12] = useState()
	const [isTaxi12, setIsTaxi12] = useState()
	//
	const [name13, setName13] = useState()
	const [number13, setNumber13] = useState()
	const [BY13, setBY13] = useState()
	const [isGoalie13, setIsGoalie13] = useState()
	const [isTaxi13, setIsTaxi13] = useState()
	//
	const [name14, setName14] = useState()
	const [number14, setNumber14] = useState()
	const [BY14, setBY14] = useState()
	const [isGoalie14, setIsGoalie14] = useState()
	const [isTaxi14, setIsTaxi14] = useState()
	//
	const [name15, setName15] = useState()
	const [number15, setNumber15] = useState()
	const [BY15, setBY15] = useState()
	const [isGoalie15, setIsGoalie15] = useState()
	const [isTaxi15, setIsTaxi15] = useState()
	//
	const [name16, setName16] = useState()
	const [number16, setNumber16] = useState()
	const [BY16, setBY16] = useState()
	const [isGoalie16, setIsGoalie16] = useState()
	const [isTaxi16, setIsTaxi16] = useState()
	//
	const [name17, setName17] = useState()
	const [number17, setNumber17] = useState()
	const [BY17, setBY17] = useState()
	const [isGoalie17, setIsGoalie17] = useState()
	const [isTaxi17, setIsTaxi17] = useState()
	//
	const [name18, setName18] = useState()
	const [number18, setNumber18] = useState()
	const [BY18, setBY18] = useState()
	const [isGoalie18, setIsGoalie18] = useState()
	const [isTaxi18, setIsTaxi18] = useState()
	//
	const [name19, setName19] = useState()
	const [number19, setNumber19] = useState()
	const [BY19, setBY19] = useState()
	const [isGoalie19, setIsGoalie19] = useState()
	const [isTaxi19, setIsTaxi19] = useState()
	//
	const [name20, setName20] = useState()
	const [number20, setNumber20] = useState()
	const [BY20, setBY20] = useState()
	const [isGoalie20, setIsGoalie20] = useState()
	const [isTaxi20, setIsTaxi20] = useState()
	//
	const [name21, setName21] = useState()
	const [number21, setNumber21] = useState()
	const [BY21, setBY21] = useState()
	const [isGoalie21, setIsGoalie21] = useState()
	const [isTaxi21, setIsTaxi21] = useState()
	//
	const [name22, setName22] = useState()
	const [number22, setNumber22] = useState()
	const [BY22, setBY22] = useState()
	const [isGoalie22, setIsGoalie22] = useState()
	const [isTaxi22, setIsTaxi22] = useState()
	//
	const [name23, setName23] = useState()
	const [number23, setNumber23] = useState()
	const [BY23, setBY23] = useState()
	const [isGoalie23, setIsGoalie23] = useState()
	const [isTaxi23, setIsTaxi23] = useState()
	//
	const [name24, setName24] = useState()
	const [number24, setNumber24] = useState()
	const [BY24, setBY24] = useState()
	const [isGoalie24, setIsGoalie24] = useState()
	const [isTaxi24, setIsTaxi24] = useState()
	//
	const [name25, setName25] = useState()
	const [number25, setNumber25] = useState()
	const [BY25, setBY25] = useState()
	const [isGoalie25, setIsGoalie25] = useState()
	const [isTaxi25, setIsTaxi25] = useState()
	//
	const [name26, setName26] = useState()
	const [number26, setNumber26] = useState()
	const [BY26, setBY26] = useState()
	const [isGoalie26, setIsGoalie26] = useState()
	const [isTaxi26, setIsTaxi26] = useState()
	//
	const [name27, setName27] = useState()
	const [number27, setNumber27] = useState()
	const [BY27, setBY27] = useState()
	const [isGoalie27, setIsGoalie27] = useState()
	const [isTaxi27, setIsTaxi27] = useState()
	//
	const [name28, setName28] = useState()
	const [number28, setNumber28] = useState()
	const [BY28, setBY28] = useState()
	const [isGoalie28, setIsGoalie28] = useState()
	const [isTaxi28, setIsTaxi28] = useState()
	//
	const [name29, setName29] = useState()
	const [number29, setNumber29] = useState()
	const [BY29, setBY29] = useState()
	const [isGoalie29, setIsGoalie29] = useState()
	const [isTaxi29, setIsTaxi29] = useState()
	//
	const [name30, setName30] = useState()
	const [number30, setNumber30] = useState()
	const [BY30, setBY30] = useState()
	const [isGoalie30, setIsGoalie30] = useState()
	const [isTaxi30, setIsTaxi30] = useState()
	//
	const [isActive1, setIsActive1] = useState()
	const [isActive2, setIsActive2] = useState()
	const [isActive3, setIsActive3] = useState()
	const [isActive4, setIsActive4] = useState()
	const [isActive5, setIsActive5] = useState()
	const [isActive6, setIsActive6] = useState()
	const [isActive7, setIsActive7] = useState()
	const [isActive8, setIsActive8] = useState()
	const [isActive9, setIsActive9] = useState()
	const [isActive10, setIsActive10] = useState()
	const [isActive11, setIsActive11] = useState()
	const [isActive12, setIsActive12] = useState()
	const [isActive13, setIsActive13] = useState()
	const [isActive14, setIsActive14] = useState()
	const [isActive15, setIsActive15] = useState()
	const [isActive16, setIsActive16] = useState()
	const [isActive17, setIsActive17] = useState()
	const [isActive18, setIsActive18] = useState()
	const [isActive19, setIsActive19] = useState()
	const [isActive20, setIsActive20] = useState()
	const [isActive21, setIsActive21] = useState()
	const [isActive22, setIsActive22] = useState()
	const [isActive23, setIsActive23] = useState()
	const [isActive24, setIsActive24] = useState()
	const [isActive25, setIsActive25] = useState()
	const [isActive26, setIsActive26] = useState()
	const [isActive27, setIsActive27] = useState()
	const [isActive28, setIsActive28] = useState()
	const [isActive29, setIsActive29] = useState()
	const [isActive30, setIsActive30] = useState()
	const [isCommitted1, setIsCommitted1] = useState()
	const [isCommitted2, setIsCommitted2] = useState()
	const [isCommitted3, setIsCommitted3] = useState()
	const [isCommitted4, setIsCommitted4] = useState()
	const [isCommitted5, setIsCommitted5] = useState()
	const [isCommitted6, setIsCommitted6] = useState()
	const [isCommitted7, setIsCommitted7] = useState()
	const [isCommitted8, setIsCommitted8] = useState()
	const [isCommitted9, setIsCommitted9] = useState()
	const [isCommitted10, setIsCommitted10] = useState()
	const [isCommitted11, setIsCommitted11] = useState()
	const [isCommitted12, setIsCommitted12] = useState()
	const [isCommitted13, setIsCommitted13] = useState()
	const [isCommitted14, setIsCommitted14] = useState()
	const [isCommitted15, setIsCommitted15] = useState()
	const [isCommitted16, setIsCommitted16] = useState()
	const [isCommitted17, setIsCommitted17] = useState()
	const [isCommitted18, setIsCommitted18] = useState()
	const [isCommitted19, setIsCommitted19] = useState()
	const [isCommitted20, setIsCommitted20] = useState()
	const [isCommitted21, setIsCommitted21] = useState()
	const [isCommitted22, setIsCommitted22] = useState()
	const [isCommitted23, setIsCommitted23] = useState()
	const [isCommitted24, setIsCommitted24] = useState()
	const [isCommitted25, setIsCommitted25] = useState()
	const [isCommitted26, setIsCommitted26] = useState()
	const [isCommitted27, setIsCommitted27] = useState()
	const [isCommitted28, setIsCommitted28] = useState()
	const [isCommitted29, setIsCommitted29] = useState()
	const [isCommitted30, setIsCommitted30] = useState()
	//First, lets tap into the following route on the backend to get all
	//the registered teams
	useEffect(() => {
		const sendRequest = async () => {
			setIsLoading(true)
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL + `/registration/allRegisteredTeams`
				)

				const responseData = await response.json()

				if (!response.ok) {
					throw new Error(responseData.message)
				}

				setLoadedTeams(responseData.allRegisteredTeams)
				setEmailAddresses(responseData.allEmailAddresses)
				setIsLoading(false)
			} catch (err) {
				setError(err.message)
			}
			setTeamToArchive(false)
			setIsLoading(false)
		}
		sendRequest()
	}, [teamToArchive])
	//
	//
	//console.log('loadedTeams: ' + JSON.stringify(loadedTeams))
	//
	//
	let emailAddressesArray, uniqueEmailAddresses
	emailAddressesArray = []
	if (emailAddresses) {
		emailAddresses.forEach((emailAddress) => {
			emailAddressesArray.push(emailAddress)
		})
	}
	//
	//
	if (emailAddressesArray.length > 0) {
		uniqueEmailAddresses = emailAddressesArray.filter(
			(value, index) => emailAddressesArray.indexOf(value) === index
		)
	}
	//
	//
	let navigate = useNavigate()
	//
	//
	const errorHandler = () => {
		setError(null)
	}
	//
	//console.log('loadedTeams: ' + JSON.stringify(loadedTeams))
	//
	//
	let teamsFilteredByYear
	teamsFilteredByYear = []
	const handleFilterByYear = (e) => {
		e.preventDefault()
		const selectedYear = e.target.value
		setYear(selectedYear)

		loadedTeams.forEach((team) => {
			if (team.year === Number(selectedYear)) {
				teamsFilteredByYear.push(team)
			}
		})
		setFilteredLoadedTeams(teamsFilteredByYear)
	}
	//
	//
	//
	//
	const showEmailModal = (e) => {
		const value = e.target.id
		setEmailModal(true)
	}
	//
	//
	const cancelEmailHandler = () => {
		setEmailModal(false)
	}
	//
	//
	//
	function sendEmail(e) {
		e.preventDefault()

		emailjs
			.sendForm(
				'service_qeuvtpo',
				'template_htf42za',
				document.getElementById('emailForm'),
				'GFXJpgnQXBXpF8Y3w'
			)
			.then((res) => {
				console.log(res)
				alert('Your email is on its way!')
				setEmailModal(false)
			})
			.catch((err) => console.log(err))
	}
	//
	//
	//
	//
	const showRosterModal = (e, key) => {
		const value = e.target.id
		console.log('team:' + JSON.stringify(loadedTeams[key]))
		console.log('roster for: ' + key)
		setTeamIdForCommittedEdits(loadedTeams[key]._id)
		setTeamName(loadedTeams[key].team)
		setDateOfLastEdit(loadedTeams[key].date)
		//
		setName1(loadedTeams[key].name1)
		setNumber1(loadedTeams[key].number1)
		setBY1(loadedTeams[key].BY1)
		setIsGoalie1(loadedTeams[key].playerIsGoalie1)
		setIsTaxi1(loadedTeams[key].playerIsTaxi1)
		//
		setName2(loadedTeams[key].name2)
		setNumber2(loadedTeams[key].number2)
		setBY2(loadedTeams[key].BY2)
		setIsGoalie2(loadedTeams[key].playerIsGoalie2)
		setIsTaxi2(loadedTeams[key].playerIsTaxi2)
		//
		setName3(loadedTeams[key].name3)
		setNumber3(loadedTeams[key].number3)
		setBY3(loadedTeams[key].BY3)
		setIsGoalie3(loadedTeams[key].playerIsGoalie3)
		setIsTaxi3(loadedTeams[key].playerIsTaxi3)
		//
		setName4(loadedTeams[key].name4)
		setNumber4(loadedTeams[key].number4)
		setBY4(loadedTeams[key].BY4)
		setIsGoalie4(loadedTeams[key].playerIsGoalie4)
		setIsTaxi4(loadedTeams[key].playerIsTaxi4)
		//
		setName5(loadedTeams[key].name5)
		setNumber5(loadedTeams[key].number5)
		setBY5(loadedTeams[key].BY5)
		setIsGoalie5(loadedTeams[key].playerIsGoalie5)
		setIsTaxi5(loadedTeams[key].playerIsTaxi5)
		//
		setName6(loadedTeams[key].name6)
		setNumber6(loadedTeams[key].number6)
		setBY6(loadedTeams[key].BY6)
		setIsGoalie6(loadedTeams[key].playerIsGoalie6)
		setIsTaxi6(loadedTeams[key].playerIsTaxi6)
		//
		setName7(loadedTeams[key].name7)
		setNumber7(loadedTeams[key].number7)
		setBY7(loadedTeams[key].BY7)
		setIsGoalie7(loadedTeams[key].playerIsGoalie7)
		setIsTaxi7(loadedTeams[key].playerIsTaxi7)
		//
		setName8(loadedTeams[key].name8)
		setNumber8(loadedTeams[key].number8)
		setBY8(loadedTeams[key].BY8)
		setIsGoalie8(loadedTeams[key].playerIsGoalie8)
		setIsTaxi8(loadedTeams[key].playerIsTaxi8)
		//
		setName9(loadedTeams[key].name9)
		setNumber9(loadedTeams[key].number9)
		setBY9(loadedTeams[key].BY9)
		setIsGoalie9(loadedTeams[key].playerIsGoalie9)
		setIsTaxi9(loadedTeams[key].playerIsTaxi9)
		//
		setName10(loadedTeams[key].name10)
		setNumber10(loadedTeams[key].number10)
		setBY10(loadedTeams[key].BY10)
		setIsGoalie10(loadedTeams[key].playerIsGoalie10)
		setIsTaxi10(loadedTeams[key].playerIsTaxi10)
		//
		setName11(loadedTeams[key].name11)
		setNumber11(loadedTeams[key].number11)
		setBY11(loadedTeams[key].BY11)
		setIsGoalie11(loadedTeams[key].playerIsGoalie11)
		setIsTaxi11(loadedTeams[key].playerIsTaxi11)
		//
		setName12(loadedTeams[key].name12)
		setNumber12(loadedTeams[key].number12)
		setBY12(loadedTeams[key].BY12)
		setIsGoalie12(loadedTeams[key].playerIsGoalie12)
		setIsTaxi12(loadedTeams[key].playerIsTaxi12)
		//
		setName13(loadedTeams[key].name13)
		setNumber13(loadedTeams[key].number13)
		setBY13(loadedTeams[key].BY13)
		setIsGoalie13(loadedTeams[key].playerIsGoalie13)
		setIsTaxi13(loadedTeams[key].playerIsTaxi13)
		//
		setName14(loadedTeams[key].name14)
		setNumber14(loadedTeams[key].number14)
		setBY14(loadedTeams[key].BY14)
		setIsGoalie14(loadedTeams[key].playerIsGoalie14)
		setIsTaxi14(loadedTeams[key].playerIsTaxi14)
		//
		setName15(loadedTeams[key].name15)
		setNumber15(loadedTeams[key].number15)
		setBY15(loadedTeams[key].BY15)
		setIsGoalie15(loadedTeams[key].playerIsGoalie15)
		setIsTaxi15(loadedTeams[key].playerIsTaxi15)
		//
		setName16(loadedTeams[key].name16)
		setNumber16(loadedTeams[key].number16)
		setBY16(loadedTeams[key].BY16)
		setIsGoalie16(loadedTeams[key].playerIsGoalie16)
		setIsTaxi16(loadedTeams[key].playerIsTaxi16)
		//
		setName17(loadedTeams[key].name17)
		setNumber17(loadedTeams[key].number17)
		setBY17(loadedTeams[key].BY17)
		setIsGoalie17(loadedTeams[key].playerIsGoalie17)
		setIsTaxi17(loadedTeams[key].playerIsTaxi17)
		//
		setName18(loadedTeams[key].name18)
		setNumber18(loadedTeams[key].number18)
		setBY18(loadedTeams[key].BY18)
		setIsGoalie18(loadedTeams[key].playerIsGoalie18)
		setIsTaxi18(loadedTeams[key].playerIsTaxi18)
		//
		setName19(loadedTeams[key].name19)
		setNumber19(loadedTeams[key].number19)
		setBY19(loadedTeams[key].BY19)
		setIsGoalie19(loadedTeams[key].playerIsGoalie19)
		setIsTaxi19(loadedTeams[key].playerIsTaxi19)
		//
		setName20(loadedTeams[key].name20)
		setNumber20(loadedTeams[key].number20)
		setBY20(loadedTeams[key].BY20)
		setIsGoalie20(loadedTeams[key].playerIsGoalie20)
		setIsTaxi20(loadedTeams[key].playerIsTaxi20)
		//
		setName21(loadedTeams[key].name21)
		setNumber21(loadedTeams[key].number21)
		setBY21(loadedTeams[key].BY21)
		setIsGoalie21(loadedTeams[key].playerIsGoalie21)
		setIsTaxi21(loadedTeams[key].playerIsTaxi21)
		//
		setName22(loadedTeams[key].name22)
		setNumber22(loadedTeams[key].number22)
		setBY22(loadedTeams[key].BY22)
		setIsGoalie22(loadedTeams[key].playerIsGoalie22)
		setIsTaxi22(loadedTeams[key].playerIsTaxi22)
		//
		setName23(loadedTeams[key].name23)
		setNumber23(loadedTeams[key].number23)
		setBY23(loadedTeams[key].BY23)
		setIsGoalie23(loadedTeams[key].playerIsGoalie23)
		setIsTaxi23(loadedTeams[key].playerIsTaxi23)
		//
		setName24(loadedTeams[key].name24)
		setNumber24(loadedTeams[key].number24)
		setBY24(loadedTeams[key].BY24)
		setIsGoalie24(loadedTeams[key].playerIsGoalie24)
		setIsTaxi24(loadedTeams[key].playerIsTaxi24)
		//
		setName25(loadedTeams[key].name25)
		setNumber25(loadedTeams[key].number25)
		setBY25(loadedTeams[key].BY25)
		setIsGoalie25(loadedTeams[key].playerIsGoalie25)
		setIsTaxi25(loadedTeams[key].playerIsTaxi25)
		//
		setName26(loadedTeams[key].name26)
		setNumber26(loadedTeams[key].number26)
		setBY26(loadedTeams[key].BY26)
		setIsGoalie26(loadedTeams[key].playerIsGoalie26)
		setIsTaxi26(loadedTeams[key].playerIsTaxi26)
		//
		setName27(loadedTeams[key].name27)
		setNumber27(loadedTeams[key].number27)
		setBY27(loadedTeams[key].BY27)
		setIsGoalie27(loadedTeams[key].playerIsGoalie27)
		setIsTaxi27(loadedTeams[key].playerIsTaxi27)
		//
		setName28(loadedTeams[key].name28)
		setNumber28(loadedTeams[key].number28)
		setBY28(loadedTeams[key].BY28)
		setIsGoalie28(loadedTeams[key].playerIsGoalie28)
		setIsTaxi28(loadedTeams[key].playerIsTaxi28)
		//
		setName29(loadedTeams[key].name29)
		setNumber29(loadedTeams[key].number29)
		setBY29(loadedTeams[key].BY29)
		setIsGoalie29(loadedTeams[key].playerIsGoalie29)
		setIsTaxi29(loadedTeams[key].playerIsTaxi29)
		//
		setName30(loadedTeams[key].name30)
		setNumber30(loadedTeams[key].number30)
		setBY30(loadedTeams[key].BY30)
		setIsGoalie30(loadedTeams[key].playerIsGoalie30)
		setIsTaxi30(loadedTeams[key].playerIsTaxi30)
		//
		setIsActive1(loadedTeams[key].playerIsActive1)
		setIsActive2(loadedTeams[key].playerIsActive2)
		setIsActive3(loadedTeams[key].playerIsActive3)
		setIsActive4(loadedTeams[key].playerIsActive4)
		setIsActive5(loadedTeams[key].playerIsActive5)
		setIsActive6(loadedTeams[key].playerIsActive6)
		setIsActive7(loadedTeams[key].playerIsActive7)
		setIsActive8(loadedTeams[key].playerIsActive8)
		setIsActive9(loadedTeams[key].playerIsActive9)
		setIsActive10(loadedTeams[key].playerIsActive10)
		setIsActive11(loadedTeams[key].playerIsActive11)
		setIsActive12(loadedTeams[key].playerIsActive12)
		setIsActive13(loadedTeams[key].playerIsActive13)
		setIsActive14(loadedTeams[key].playerIsActive14)
		setIsActive15(loadedTeams[key].playerIsActive15)
		setIsActive16(loadedTeams[key].playerIsActive16)
		setIsActive17(loadedTeams[key].playerIsActive17)
		setIsActive18(loadedTeams[key].playerIsActive18)
		setIsActive19(loadedTeams[key].playerIsActive19)
		setIsActive20(loadedTeams[key].playerIsActive20)
		setIsActive21(loadedTeams[key].playerIsActive21)
		setIsActive22(loadedTeams[key].playerIsActive22)
		setIsActive23(loadedTeams[key].playerIsActive23)
		setIsActive24(loadedTeams[key].playerIsActive24)
		setIsActive25(loadedTeams[key].playerIsActive25)
		setIsActive26(loadedTeams[key].playerIsActive26)
		setIsActive27(loadedTeams[key].playerIsActive27)
		setIsActive28(loadedTeams[key].playerIsActive28)
		setIsActive29(loadedTeams[key].playerIsActive29)
		setIsActive30(loadedTeams[key].playerIsActive30)
		//
		setIsCommitted1(loadedTeams[key].playerIsCommitted1)
		setIsCommitted2(loadedTeams[key].playerIsCommitted2)
		setIsCommitted3(loadedTeams[key].playerIsCommitted3)
		setIsCommitted4(loadedTeams[key].playerIsCommitted4)
		setIsCommitted5(loadedTeams[key].playerIsCommitted5)
		setIsCommitted6(loadedTeams[key].playerIsCommitted6)
		setIsCommitted7(loadedTeams[key].playerIsCommitted7)
		setIsCommitted8(loadedTeams[key].playerIsCommitted8)
		setIsCommitted9(loadedTeams[key].playerIsCommitted9)
		setIsCommitted10(loadedTeams[key].playerIsCommitted10)
		setIsCommitted11(loadedTeams[key].playerIsCommitted11)
		setIsCommitted12(loadedTeams[key].playerIsCommitted12)
		setIsCommitted13(loadedTeams[key].playerIsCommitted13)
		setIsCommitted14(loadedTeams[key].playerIsCommitted14)
		setIsCommitted15(loadedTeams[key].playerIsCommitted15)
		setIsCommitted16(loadedTeams[key].playerIsCommitted16)
		setIsCommitted17(loadedTeams[key].playerIsCommitted17)
		setIsCommitted18(loadedTeams[key].playerIsCommitted18)
		setIsCommitted19(loadedTeams[key].playerIsCommitted19)
		setIsCommitted20(loadedTeams[key].playerIsCommitted20)
		setIsCommitted21(loadedTeams[key].playerIsCommitted21)
		setIsCommitted22(loadedTeams[key].playerIsCommitted22)
		setIsCommitted23(loadedTeams[key].playerIsCommitted23)
		setIsCommitted24(loadedTeams[key].playerIsCommitted24)
		setIsCommitted25(loadedTeams[key].playerIsCommitted25)
		setIsCommitted26(loadedTeams[key].playerIsCommitted26)
		setIsCommitted27(loadedTeams[key].playerIsCommitted27)
		setIsCommitted28(loadedTeams[key].playerIsCommitted28)
		setIsCommitted29(loadedTeams[key].playerIsCommitted29)
		setIsCommitted30(loadedTeams[key].playerIsCommitted30)
		setRosterModal(true)
	}
	//
	const cancelRosterHandler = () => {
		setRosterModal(false)
	}
	//
	//
	let totalActivePlayers = 0
	if (loadedTeams) {
		if (isActive1) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive2) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive3) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive4) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive5) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive6) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive7) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive8) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive9) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive10) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive11) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive12) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive13) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive14) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive15) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive16) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive17) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive18) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive19) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive20) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive21) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive22) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive23) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive24) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive25) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive26) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive27) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive28) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive29) {
			totalActivePlayers = totalActivePlayers + 1
		}
		if (isActive30) {
			totalActivePlayers = totalActivePlayers + 1
		}
	}
	//
	//
	const playerIsCommittedCheckbox1 = (e) => {
		if (isCommitted1) {
			setIsCommitted1(false)
		}
		if (!isCommitted1) {
			setIsCommitted1(true)
		}
	}
	const playerIsCommittedCheckbox2 = (e) => {
		if (isCommitted2) {
			setIsCommitted2(false)
		}
		if (!isCommitted2) {
			setIsCommitted2(true)
		}
	}
	const playerIsCommittedCheckbox3 = (e) => {
		if (isCommitted3) {
			setIsCommitted3(false)
		}
		if (!isCommitted3) {
			setIsCommitted3(true)
		}
	}
	const playerIsCommittedCheckbox4 = (e) => {
		if (isCommitted4) {
			setIsCommitted4(false)
		}
		if (!isCommitted4) {
			setIsCommitted4(true)
		}
	}
	const playerIsCommittedCheckbox5 = (e) => {
		if (isCommitted5) {
			setIsCommitted5(false)
		}
		if (!isCommitted5) {
			setIsCommitted5(true)
		}
	}
	const playerIsCommittedCheckbox6 = (e) => {
		if (isCommitted6) {
			setIsCommitted6(false)
		}
		if (!isCommitted6) {
			setIsCommitted6(true)
		}
	}
	const playerIsCommittedCheckbox7 = (e) => {
		if (isCommitted7) {
			setIsCommitted7(false)
		}
		if (!isCommitted7) {
			setIsCommitted7(true)
		}
	}
	const playerIsCommittedCheckbox8 = (e) => {
		if (isCommitted8) {
			setIsCommitted8(false)
		}
		if (!isCommitted8) {
			setIsCommitted8(true)
		}
	}
	const playerIsCommittedCheckbox9 = (e) => {
		if (isCommitted9) {
			setIsCommitted9(false)
		}
		if (!isCommitted9) {
			setIsCommitted9(true)
		}
	}
	const playerIsCommittedCheckbox10 = (e) => {
		if (isCommitted10) {
			setIsCommitted10(false)
		}
		if (!isCommitted10) {
			setIsCommitted10(true)
		}
	}
	const playerIsCommittedCheckbox11 = (e) => {
		if (isCommitted11) {
			setIsCommitted11(false)
		}
		if (!isCommitted11) {
			setIsCommitted11(true)
		}
	}
	const playerIsCommittedCheckbox12 = (e) => {
		if (isCommitted12) {
			setIsCommitted12(false)
		}
		if (!isCommitted12) {
			setIsCommitted12(true)
		}
	}
	const playerIsCommittedCheckbox13 = (e) => {
		if (isCommitted13) {
			setIsCommitted13(false)
		}
		if (!isCommitted13) {
			setIsCommitted13(true)
		}
	}
	const playerIsCommittedCheckbox14 = (e) => {
		if (isCommitted14) {
			setIsCommitted14(false)
		}
		if (!isCommitted14) {
			setIsCommitted14(true)
		}
	}
	const playerIsCommittedCheckbox15 = (e) => {
		if (isCommitted15) {
			setIsCommitted15(false)
		}
		if (!isCommitted15) {
			setIsCommitted15(true)
		}
	}
	const playerIsCommittedCheckbox16 = (e) => {
		if (isCommitted16) {
			setIsCommitted16(false)
		}
		if (!isCommitted16) {
			setIsCommitted16(true)
		}
	}
	const playerIsCommittedCheckbox17 = (e) => {
		if (isCommitted17) {
			setIsCommitted17(false)
		}
		if (!isCommitted17) {
			setIsCommitted17(true)
		}
	}
	const playerIsCommittedCheckbox18 = (e) => {
		if (isCommitted18) {
			setIsCommitted18(false)
		}
		if (!isCommitted18) {
			setIsCommitted18(true)
		}
	}
	const playerIsCommittedCheckbox19 = (e) => {
		if (isCommitted19) {
			setIsCommitted19(false)
		}
		if (!isCommitted19) {
			setIsCommitted19(true)
		}
	}
	const playerIsCommittedCheckbox20 = (e) => {
		if (isCommitted20) {
			setIsCommitted20(false)
		}
		if (!isCommitted20) {
			setIsCommitted20(true)
		}
	}
	const playerIsCommittedCheckbox21 = (e) => {
		if (isCommitted21) {
			setIsCommitted21(false)
		}
		if (!isCommitted21) {
			setIsCommitted21(true)
		}
	}
	const playerIsCommittedCheckbox22 = (e) => {
		if (isCommitted22) {
			setIsCommitted22(false)
		}
		if (!isCommitted22) {
			setIsCommitted22(true)
		}
	}
	const playerIsCommittedCheckbox23 = (e) => {
		if (isCommitted23) {
			setIsCommitted23(false)
		}
		if (!isCommitted23) {
			setIsCommitted23(true)
		}
	}
	const playerIsCommittedCheckbox24 = (e) => {
		if (isCommitted24) {
			setIsCommitted24(false)
		}
		if (!isCommitted24) {
			setIsCommitted24(true)
		}
	}
	const playerIsCommittedCheckbox25 = (e) => {
		if (isCommitted25) {
			setIsCommitted25(false)
		}
		if (!isCommitted25) {
			setIsCommitted25(true)
		}
	}
	const playerIsCommittedCheckbox26 = (e) => {
		if (isCommitted26) {
			setIsCommitted26(false)
		}
		if (!isCommitted26) {
			setIsCommitted26(true)
		}
	}
	const playerIsCommittedCheckbox27 = (e) => {
		if (isCommitted27) {
			setIsCommitted27(false)
		}
		if (!isCommitted27) {
			setIsCommitted27(true)
		}
	}
	const playerIsCommittedCheckbox28 = (e) => {
		if (isCommitted28) {
			setIsCommitted28(false)
		}
		if (!isCommitted28) {
			setIsCommitted28(true)
		}
	}
	const playerIsCommittedCheckbox29 = (e) => {
		if (isCommitted29) {
			setIsCommitted29(false)
		}
		if (!isCommitted29) {
			setIsCommitted29(true)
		}
	}
	const playerIsCommittedCheckbox30 = (e) => {
		if (isCommitted30) {
			setIsCommitted30(false)
		}
		if (!isCommitted30) {
			setIsCommitted30(true)
		}
	}
	//
	//
	//
	//
	//
	//******************************************************** */
	//
	//     'Move to archive' warning
	//
	//******************************************************** */
	const showMoveToArchiveHandler = (e) => {
		const value = e.target.id
		console.log(loadedTeams[value]._id)
		setTeamId(loadedTeams[value]._id)
		setShowConfirmMoveToArchiveModal(true)
	}

	const cancelMoveToArchiveHandler = () => {
		setShowConfirmMoveToArchiveModal(false)
	}

	const confirmMoveToArchiveHandler = async () => {
		setShowConfirmMoveToArchiveModal(false)
		try {
			await sendRequest(
				`${process.env.REACT_APP_BACKEND_URL}/admin/${teamId}/archiveCurrentToggleTeam`,
				'PATCH'
			)
		} catch (err) {}
		setTeamToArchive(true)
	}
	//
	//
	//
	const committedHandler = async (event) => {
		console.log(teamName)
		console.log(name1)
		console.log(isCommitted1)
		console.log(teamIdForCommittedEdits)
		event.preventDefault()

		try {
			setIsLoading(true)

			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + '/admin/markAsCommitted',
				{
					method: 'PATCH',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						teamIdForCommittedEdits,
						isCommitted1,
						isCommitted2,
						isCommitted3,
						isCommitted4,
						isCommitted5,
						isCommitted6,
						isCommitted7,
						isCommitted8,
						isCommitted9,
						isCommitted10,
						isCommitted11,
						isCommitted12,
						isCommitted13,
						isCommitted14,
						isCommitted15,
						isCommitted16,
						isCommitted17,
						isCommitted18,
						isCommitted19,
						isCommitted20,
						isCommitted21,
						isCommitted22,
						isCommitted23,
						isCommitted24,
						isCommitted25,
						isCommitted26,
						isCommitted27,
						isCommitted28,
						isCommitted29,
						isCommitted30,
					}),
				}
			)
			const responseData = await response.json()
			if (!response.ok) {
				throw new Error(responseData.message)
			}
			setIsLoading(false)
			//navigate('/admin/suspended')
			//navigate('/admin/leagues/current')
			//setRosterModal(false)
			window.location.reload()
		} catch (err) {
			setIsLoading(false)
			setError(
				err.message || 'Something went wrong with marking players as committed'
			)
		}
	}
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//
	//console.log('filteredLoadedTeams: ' + JSON.stringify(filteredLoadedTeams))
	return (
		<React.Fragment>
			<ModalEmail
				show={emailModal}
				onCancel={cancelEmailHandler}
				header='Send an Email'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div
							className='container'
							style={{ marginTop: '0.5rem', width: '80%' }}
						>
							<form
								method='post'
								enctype='text/plain'
								id='emailForm'
								style={{ height: '80%' }}
							>
								<label>Subject: </label>
								<input
									type='text'
									name='subject'
									className='form-control emailInput'
									defaultValue={'New Message from PBHL'}
									required
								></input>

								<br></br>
								<br></br>

								<label>From: </label>
								<input
									type='text'
									name='from'
									className='form-control emailInput'
									defaultValue='Pennsylvania Ball Hockey League'
								></input>

								<br></br>
								<br></br>

								<label>To: </label>
								<input
									type='text'
									name='to'
									className='form-control emailInput'
									defaultValue={uniqueEmailAddresses && uniqueEmailAddresses}
								></input>

								<br></br>
								<br></br>

								<label>Message</label>
								<textarea
									name='message'
									rows='6'
									className='form-control emailInput'
								/>
								<button class='buttonButton2' type='submit' onClick={sendEmail}>
									Send
								</button>
								<button
									className='buttonButton2'
									style={{ marginLeft: '2rem' }}
									onClick={cancelEmailHandler}
								>
									CANCEL
								</button>
							</form>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<ModalEmail
				show={rosterModal}
				onCancel={cancelRosterHandler}
				header={teamName}
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<div className='container' style={{ marginTop: '0.5rem' }}>
							<div
								style={{
									textAlign: 'center',
									fontWeight: 'bolder',
									marginBottom: '1.5rem',
								}}
							>
								Date of last edit: {dateOfLastEdit}
							</div>
							<h3>Total Active Players: {totalActivePlayers}</h3>
							<p style={{ textAlign: 'center', fontSize: 'small' }}>
								(G) = Goalie
							</p>
							<p style={{ textAlign: 'center', fontSize: 'small' }}>
								(T) = Taxi Squad
							</p>
							<table className='rosterModalTable'>
								<thead className='tableHeader2'>
									<tr>
										<th>Name</th>
										<th>#</th>
										<th></th>
										<th style={{ textAlign: 'center' }}>Birth Year</th>
										<th></th>
										<th></th>
										<th>Committed?</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name1} {isGoalie1 && '(G)'} {isTaxi1 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number1}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY1}</td>
										{name1 && isActive1 && <td></td>}
										{name1 && !isActive1 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name1 && (
											<td>
												<input
													id='committed1'
													type='checkbox'
													checked={isCommitted1}
													onChange={playerIsCommittedCheckbox1}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name2} {isGoalie2 && '(G)'} {isTaxi2 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number2}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY2}</td>

										{name2 && isActive2 && <td></td>}

										{name2 && !isActive2 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name2 && (
											<td>
												<input
													id='committed2'
													type='checkbox'
													checked={isCommitted2}
													onChange={playerIsCommittedCheckbox2}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name3} {isGoalie3 && '(G)'} {isTaxi3 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number3}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY3}</td>

										{name3 && isActive3 && <td></td>}

										{name3 && !isActive3 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name3 && (
											<td>
												<input
													id='committed3'
													type='checkbox'
													checked={isCommitted3}
													onChange={playerIsCommittedCheckbox3}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name4} {isGoalie4 && '(G)'} {isTaxi4 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number4}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY4}</td>

										{name4 && isActive4 && <td></td>}

										{name4 && !isActive4 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name4 && (
											<td>
												<input
													id='committed4'
													type='checkbox'
													checked={isCommitted4}
													onChange={playerIsCommittedCheckbox4}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name5} {isGoalie5 && '(G)'} {isTaxi5 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number5}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY5}</td>

										{name5 && isActive5 && <td></td>}

										{name5 && !isActive5 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name5 && (
											<td>
												<input
													id='committed5'
													type='checkbox'
													checked={isCommitted5}
													onChange={playerIsCommittedCheckbox5}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name6} {isGoalie6 && '(G)'} {isTaxi6 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number6}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY6}</td>

										{name6 && isActive6 && <td></td>}

										{name6 && !isActive6 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name6 && (
											<td>
												<input
													id='committed6'
													type='checkbox'
													checked={isCommitted6}
													onChange={playerIsCommittedCheckbox6}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name7}
											{isGoalie7 && '(G)'} {isTaxi7 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number7}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY7}</td>

										{name7 && isActive7 && <td></td>}

										{name7 && !isActive7 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name7 && (
											<td>
												<input
													id='committed7'
													type='checkbox'
													checked={isCommitted7}
													onChange={playerIsCommittedCheckbox7}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name8} {isGoalie8 && '(G)'} {isTaxi8 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number8}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY8}</td>

										{name8 && isActive8 && <td></td>}

										{name8 && !isActive8 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name8 && (
											<td>
												<input
													id='committed8'
													type='checkbox'
													checked={isCommitted8}
													onChange={playerIsCommittedCheckbox8}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name9} {isGoalie9 && '(G)'} {isTaxi9 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number9}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY9}</td>

										{name9 && isActive9 && <td></td>}

										{name9 && !isActive9 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name9 && (
											<td>
												<input
													id='committed9'
													type='checkbox'
													checked={isCommitted9}
													onChange={playerIsCommittedCheckbox9}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name10} {isGoalie10 && '(G)'} {isTaxi10 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number10}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY10}</td>

										{name10 && isActive10 && <td></td>}

										{name10 && !isActive10 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name10 && (
											<td>
												<input
													id='committed10'
													type='checkbox'
													checked={isCommitted10}
													onChange={playerIsCommittedCheckbox10}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name11} {isGoalie11 && '(G)'} {isTaxi11 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number11}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY11}</td>

										{name11 && isActive11 && <td></td>}

										{name11 && !isActive11 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name11 && (
											<td>
												<input
													id='committed11'
													type='checkbox'
													checked={isCommitted11}
													onChange={playerIsCommittedCheckbox11}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name12} {isGoalie12 && '(G)'} {isTaxi12 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number12}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY12}</td>

										{name12 && isActive12 && <td></td>}

										{name12 && !isActive12 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name12 && (
											<td>
												<input
													id='committed12'
													type='checkbox'
													checked={isCommitted12}
													onChange={playerIsCommittedCheckbox12}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name13} {isGoalie13 && '(G)'} {isTaxi13 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number13}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY13}</td>

										{name13 && isActive13 && <td></td>}

										{name13 && !isActive13 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name13 && (
											<td>
												<input
													id='committed13'
													type='checkbox'
													checked={isCommitted13}
													onChange={playerIsCommittedCheckbox13}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name14} {isGoalie14 && '(G)'} {isTaxi14 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number14}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY14}</td>

										{name14 && isActive14 && <td></td>}

										{name14 && !isActive14 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name14 && (
											<td>
												<input
													id='committed14'
													type='checkbox'
													checked={isCommitted14}
													onChange={playerIsCommittedCheckbox14}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name15} {isGoalie15 && '(G)'} {isTaxi15 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number15}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY15}</td>

										{name15 && isActive15 && <td></td>}

										{name15 && !isActive15 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name15 && (
											<td>
												<input
													id='committed15'
													type='checkbox'
													checked={isCommitted15}
													onChange={playerIsCommittedCheckbox15}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name16} {isGoalie16 && '(G)'} {isTaxi16 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number16}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY16}</td>

										{name16 && isActive16 && <td></td>}

										{name16 && !isActive16 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name16 && (
											<td>
												<input
													id='committed16'
													type='checkbox'
													checked={isCommitted16}
													onChange={playerIsCommittedCheckbox16}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name17} {isGoalie17 && '(G)'} {isTaxi17 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number17}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY17}</td>

										{name17 && isActive17 && <td></td>}

										{name17 && !isActive17 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name17 && (
											<td>
												<input
													id='committed17'
													type='checkbox'
													checked={isCommitted17}
													onChange={playerIsCommittedCheckbox17}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name18} {isGoalie18 && '(G)'} {isTaxi18 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number18}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY18}</td>

										{name18 && isActive18 && <td></td>}

										{name18 && !isActive18 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name18 && (
											<td>
												<input
													id='committed18'
													type='checkbox'
													checked={isCommitted18}
													onChange={playerIsCommittedCheckbox18}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name19} {isGoalie19 && '(G)'} {isTaxi19 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number19}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY19}</td>

										{name19 && isActive19 && <td></td>}

										{name19 && !isActive19 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name19 && (
											<td>
												<input
													id='committed19'
													type='checkbox'
													checked={isCommitted19}
													onChange={playerIsCommittedCheckbox19}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name20} {isGoalie20 && '(G)'} {isTaxi20 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number20}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY20}</td>

										{name20 && isActive20 && <td></td>}

										{name20 && !isActive20 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name20 && (
											<td>
												<input
													id='committed20'
													type='checkbox'
													checked={isCommitted20}
													onChange={playerIsCommittedCheckbox20}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name21} {isGoalie21 && '(G)'} {isTaxi21 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number21}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY21}</td>

										{name21 && isActive21 && <td></td>}

										{name21 && !isActive21 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name21 && (
											<td>
												<input
													id='committed21'
													type='checkbox'
													checked={isCommitted21}
													onChange={playerIsCommittedCheckbox21}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name22} {isGoalie22 && '(G)'} {isTaxi22 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number22}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY22}</td>

										{name22 && isActive22 && <td></td>}

										{name22 && !isActive22 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name22 && (
											<td>
												<input
													id='committed22'
													type='checkbox'
													checked={isCommitted22}
													onChange={playerIsCommittedCheckbox22}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name23} {isGoalie23 && '(G)'} {isTaxi23 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number23}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY23}</td>

										{name23 && isActive23 && <td></td>}

										{name23 && !isActive23 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name23 && (
											<td>
												<input
													id='committed23'
													type='checkbox'
													checked={isCommitted23}
													onChange={playerIsCommittedCheckbox23}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name24} {isGoalie24 && '(G)'} {isTaxi24 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number24}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY24}</td>

										{name24 && isActive24 && <td></td>}

										{name24 && !isActive24 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name24 && (
											<td>
												<input
													id='committed24'
													type='checkbox'
													checked={isCommitted24}
													onChange={playerIsCommittedCheckbox24}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name25} {isGoalie25 && '(G)'} {isTaxi25 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number25}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY25}</td>

										{name25 && isActive25 && <td></td>}

										{name25 && !isActive25 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name25 && (
											<td>
												<input
													id='committed25'
													type='checkbox'
													checked={isCommitted25}
													onChange={playerIsCommittedCheckbox25}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name26} {isGoalie26 && '(G)'} {isTaxi26 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number26}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY26}</td>

										{name26 && isActive26 && <td></td>}

										{name26 && !isActive26 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name26 && (
											<td>
												<input
													id='committed26'
													type='checkbox'
													checked={isCommitted26}
													onChange={playerIsCommittedCheckbox26}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name27} {isGoalie27 && '(G)'} {isTaxi27 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number27}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY27}</td>

										{name27 && isActive27 && <td></td>}

										{name27 && !isActive27 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name27 && (
											<td>
												<input
													id='committed27'
													type='checkbox'
													checked={isCommitted27}
													onChange={playerIsCommittedCheckbox27}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name28} {isGoalie28 && '(G)'} {isTaxi28 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number28}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY28}</td>

										{name28 && isActive28 && <td></td>}

										{name28 && !isActive28 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name28 && (
											<td>
												<input
													id='committed28'
													type='checkbox'
													checked={isCommitted28}
													onChange={playerIsCommittedCheckbox28}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name29} {isGoalie29 && '(G)'} {isTaxi29 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number29}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY29}</td>

										{name29 && isActive29 && <td></td>}

										{name29 && !isActive29 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name29 && (
											<td>
												<input
													id='committed29'
													type='checkbox'
													checked={isCommitted29}
													onChange={playerIsCommittedCheckbox29}
												/>
											</td>
										)}
									</tr>
									<tr>
										<td style={{ textAlign: 'left' }}>
											{name30} {isGoalie30 && '(G)'} {isTaxi30 && '(T)'}
										</td>
										<td style={{ textAlign: 'left' }}>{number30}</td>
										<td></td>
										<td style={{ textAlign: 'center' }}>{BY30}</td>

										{name30 && isActive30 && <td></td>}

										{name30 && !isActive30 && (
											<td style={{ textAlign: 'left' }}>INACTIVE</td>
										)}
										<td></td>
										{name30 && (
											<td>
												<input
													id='committed30'
													type='checkbox'
													checked={isCommitted30}
													onChange={playerIsCommittedCheckbox30}
												/>
											</td>
										)}
									</tr>
									<div>
										<button
											className='saveCommittedBtn'
											onClick={committedHandler}
										>
											Save Committed Players
										</button>
									</div>
								</tbody>
								{/* <div>
									<button
										className='saveCommittedBtn'
										onClick={committedHandler}
									>
										Save Committed Players
									</button>
								</div> */}
							</table>
						</div>
						<br></br>
					</React.Fragment>
				}
			></ModalEmail>
			<Modal
				show={showConfirmMoveToArchiveModal}
				onCancel={cancelMoveToArchiveHandler}
				header='Are you sure?'
				footerClass='place-item__modal-actions'
				footer={
					<React.Fragment>
						<p className='modalText'>
							Are you sure you wish to Archive this team?
						</p>
						<br></br>
						<button
							className='cancelDeleteButtons buttonButton'
							inverse
							style={{ float: 'right' }}
							onClick={cancelMoveToArchiveHandler}
						>
							CANCEL
						</button>
						<button
							className='cancelDeleteButtons buttonButton'
							danger
							onClick={confirmMoveToArchiveHandler}
						>
							ARCHIVE
						</button>
					</React.Fragment>
				}
			></Modal>
			<ErrorModal error={error} onClear={errorHandler} />
			{isLoading && (
				<div className='center'>
					<LoadingSpinner />
				</div>
			)}
			{!isLoading && loadedTeams && (
				<div className='RegLeagues' style={{ marginBottom: '5rem' }}>
					<AdminNavigation />
					<h1>Registered Teams</h1>
					<div style={{ marginBottom: '1.5rem' }}>
						<div className='row'>
							<div className='LTVinstructions'>Filter by Year: </div>
						</div>
						{!isLoading && loadedTeams && (
							<div className='row'>
								<div className='col'></div>
								<div className='col-sm'>
									{/* <form className='form-control-lg'> */}
									<form className='dropdownForm'>
										<select
											id='year'
											type='text'
											tabIndex='9'
											placeholder='Year'
											className='scheduleselector'
											onChange={handleFilterByYear}
										>
											<option value='2024'>2024</option>
											<option value='2025'>2025</option>
											<option value='2026'>2026</option>
											<option value='2027'>2027</option>
											<option value='2028'>2027</option>
											<option value='2029'>2029</option>
											<option value='2030'>2030</option>
											<option value='2031'>2031</option>
											<option value='2032'>2032</option>
											<option value='2033'>2033</option>
											<option value='2034'>2034</option>
											<option value='2034'>2035</option>
										</select>
									</form>
								</div>
								<div className='col'></div>
							</div>
						)}
					</div>
					<table id='regLeagues'>
						<thead>
							<tr>
								<th>Year</th>
								<th>Organization</th>
								<th>Team Name</th>
								<th>Head Coach</th>
								<th>Assistant Coaches</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{filteredLoadedTeams &&
								filteredLoadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<AdminButton
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
													style={{
														color: 'white',
														textDecoration: 'none',
														backgroundColor: '#e0e0e0',
													}}
												>
													View Roster
												</AdminButton>
											</td>
										</tr>
									)
								})}
							{!filteredLoadedTeams &&
								loadedTeams.map((team, key) => {
									return (
										<tr key={key}>
											<td>{team.year}</td>
											<td>{team.organization}</td>
											<td>{team.team}</td>
											<td>{team.headCoach}</td>
											<td>{team.assistantCoaches}</td>
											<td>
												<AdminButton
													type='button'
													onClick={(e) => showRosterModal(e, key)}
													exact
													style={{
														color: 'white',
														textDecoration: 'none',
														backgroundColor: '#e0e0e0',
													}}
												>
													View Roster
												</AdminButton>
											</td>
											<td>
												<button
													style={{
														backgroundColor: '#92562e',
														border: '1px solid #92562e',
														borderRadius: '4px',
														color: 'white',
														padding: '0.5rem 1.5rem',
													}}
													className='buttonButton button2'
													id={key}
													onClick={(e) => showMoveToArchiveHandler(e)}
												>
													Archive Team
												</button>
											</td>
										</tr>
									)
								})}
						</tbody>
					</table>

					<AdminButton
						type='button'
						onClick={(e) => showEmailModal(e)}
						exact
						style={{
							color: 'white',
							textDecoration: 'none',
							backgroundColor: '#e0e0e0',
						}}
					>
						Send an Email
					</AdminButton>
				</div>
			)}
		</React.Fragment>
	)
}

export default AdminRegisteredTeams
