//We'll use this page for a coach to come in and register their team.
import React, { useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import Card from '../UIElements/Card'
import './Info.css'
import { AuthContext } from '../../context/auth-context'
import LoadingSpinner from '../UIElements/LoadingSpinner'
import Footer from '../UIElements/Footer'
import HomeNavLinks from './HomeNavLinks'
import HomeHomeNavigation from './HomeHomeNavigation'
import PBHL_BYLAWS from './../../../../src/PBHL_BYLAWS_2025_2.pdf'
import PBHL_RULES from './../../../../src/2025 PBHL Rules and Regulations.pdf'
import Dec23 from './../../../../src/meetingMinutes/12-14-2023.pdf'
import Jan24 from './../../../../src/meetingMinutes/1-11-2024.pdf'
import Feb24 from './../../../../src/meetingMinutes/2-15-2024.pdf'
import Mar24 from './../../../../src/meetingMinutes/3-14-2024.pdf'
import BrooklineReview2024 from './../../../../src/meetingMinutes/BrooklineReview2024.pdf'
import MidwestReview2024 from './../../../../src/meetingMinutes/MidwestReview2024.pdf'
import FalconsReview2024 from './../../../../src/meetingMinutes/FalconsReview2024.pdf'
import MurrysvilleReview2024 from './../../../../src/meetingMinutes/MurrysvilleReview2024.pdf'
import CranberryReview2024 from './../../../../src/meetingMinutes/CranberryReview2024.pdf'
import RiverviewReview2024 from './../../../../src/meetingMinutes/RiverviewReview2024.pdf'
import PennHillsReview2024 from './../../../../src/meetingMinutes/PennHillsReview2024.pdf'
import VenomAndTeamPittReviews2024 from './../../../../src/meetingMinutes/VenomAndTeamPittReviews2024.pdf'
import Dec24 from './../../../../src/meetingMinutes/12-6-2024.pdf'
//import Dec24_2 from './../../../../src/meetingMinutes/12-23-2024.pdf'

const Info = () => {
	const rulesURL =
		'https://usaballhockey.com/wp-content/uploads/USA-Ball-Hockey-Official-Rule-Book-Revised-07.06.2021.pdf'

	return (
		<div style={{ overflowX: 'hidden' }}>
			<React.Fragment>
				<HomeHomeNavigation />
				<div
					style={{
						marginTop: '8rem',
						marginBottom: '3rem',
						display: '-webkit-box',
					}}
				>
					<NavLink
						to={PBHL_BYLAWS}
						target='_blank'
						rel='noreferror'
						className='infoLink'
					>
						BY-LAWS
					</NavLink>
				</div>
				<div
					style={{
						marginBottom: '3rem',
						display: '-webkit-box',
					}}
				>
					<NavLink
						to={PBHL_RULES}
						target='_blank'
						rel='noreferror'
						className='infoLink'
					>
						2025 PBHL Rules and Regulations
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={rulesURL} target='blank' className='infoLink'>
						USA Ball Hockey Official Rule Book
					</NavLink>
				</div>
				<div className='underline-blue mb-4'></div>
				<h1
					className='displayProperty'
					style={{
						color: '#090085',
						marginBottom: '2.75rem',
						marginTop: '3rem',
					}}
				>
					Meeting Minutes
				</h1>
				{/* <div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Dec24_2} target='blank' className='infoLink'>
						December Follow-up Meeting
					</NavLink>
				</div> */}
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Dec24} target='blank' className='infoLink'>
						December 3, 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink
						to={VenomAndTeamPittReviews2024}
						target='blank'
						className='infoLink'
					>
						Venom and Team Pitt Reviews 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={PennHillsReview2024} target='blank' className='infoLink'>
						Penn Hills Tournament Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={RiverviewReview2024} target='blank' className='infoLink'>
						Riverview Tournament Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={CranberryReview2024} target='blank' className='infoLink'>
						Cranberry Tournament Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink
						to={MurrysvilleReview2024}
						target='blank'
						className='infoLink'
					>
						Murrysville Tournament Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={FalconsReview2024} target='blank' className='infoLink'>
						Falcons Spring Shootout Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={MidwestReview2024} target='blank' className='infoLink'>
						Midwest Regionals Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={BrooklineReview2024} target='blank' className='infoLink'>
						Brookline Review 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Mar24} target='blank' className='infoLink'>
						March 14, 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Feb24} target='blank' className='infoLink'>
						February 15, 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Jan24} target='blank' className='infoLink'>
						January 11, 2024
					</NavLink>
				</div>
				<div style={{ marginBottom: '3rem', display: '-webkit-box' }}>
					<NavLink to={Dec23} target='blank' className='infoLink'>
						December 14, 2023
					</NavLink>
				</div>
				<div style={{ marginTop: '17rem' }}>
					<Footer />
				</div>
			</React.Fragment>
		</div>
	)
}

export default Info
